.App {
    background-color: #f0f0f0; /* Light grey background */
    font-family: Arial, sans-serif;
    color: #333;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  
  .navbar {
    background-color: #ffffff;
    padding: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* Ensure navbar is positioned relative to its container */
    z-index: 1000; /* Bring navbar above other elements */
  }
  
  .nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  .nav-item {
    padding: 14px 20px;
  }
  
  .nav-item a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 16px;
    transition: color 0.2s;
    font-weight: bold;
  }
  
  .nav-item a:hover {
    color: #ddd;
  }
  
  .donate-now a {
    color: rgb(255, 0, 0); 
    font-weight: bold;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropbtn {
    cursor: pointer;
    color: black; /* Changed to black for visibility */
    text-decoration: none;
    font-size: 16px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure dropdown is above other elements */
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .header {
    margin: 20px 0;
  }
  
  .header h1 {
    font-size: 2.5em;
    color: #444;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; /* Ensure content is positioned relative to its container */
    z-index: 1; /* Keep content below navbar and dropdown */
  }
  
  .cover-img {
    width: 100%;
    height: 600px;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .cover-img:hover {
    transform: scale(1.05);
  }
  
  .verse {
    margin-top: 20px;
    font-size: 1.2em;
    color: #555;
    max-width: 80%;
  }
  
  .donatenow {
    background-color: #ff6347; /* Tomato background color */
    width: 80%;
    padding: 20px;
    margin: 20px auto;
    border-radius: 10px;
    color: white;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
  }
  
  .donatenow h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .donatenow p {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .btc-address {
    font-family: 'Courier New', Courier, monospace;
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
  }
  
  .donate-button {
    background-color: #28a745; /* Green button */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
  }
  
  .donate-button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
 /* Existing styles... */

.content-d {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    text-align: left;
  }
  
  .content-d h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #333; /* Dark grey color for heading */
  }
  
  .content-d p {
    font-size: 1.2em;
    margin: 10px 0;
    color: #555; /* Dark grey color for paragraph */
  }
  
  /* HomePage.css */

/* ...existing styles... */

.content-about {
    margin-top: 50px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .content-about h2 {
    color: #333;
  }
  
  .content-about p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .content-job-opportunities {
    margin-top: 50px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .content-job-opportunities h2 {
    color: #333;
  }
  
  .content-job-opportunities p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .content-donation-help {
    margin-top: 50px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .content-donation-help h2 {
    color: #333;
  }
  
  .content-donation-help p {
    font-size: 1.1em;
    line-height: 1.6;
  }
/* HomePage.css */

/* ...existing styles... */

.two-column-container {
    display: flex;
    justify-content: space-between;
  }
  
  .content-about,
  .content-job-opportunities {
    flex-basis: 48%;
  }
  
  /* Adjust flex-basis and margin for responsive design */
  
  @media (max-width: 768px) {
    .two-column-container {
      flex-direction: column;
    }
  
    .content-about,
    .content-job-opportunities {
      flex-basis: 100%;
      margin-bottom: 20px;
    }
  }

  /* HomePage.css */

/* ...existing styles... */

.thank-you-box {
    background-color: #f0f0f0;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .thank-you-content {
    text-align: center;
    color: #333;
  }
  
  .thank-you-content h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .thank-you-content p {
    font-size: 1.2em;
    line-height: 1.6;
  }
  
  .content-d-h {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    text-align: left;
    width: 80%;
  }
  
  .content-d-h h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #000000; /* Dark grey color for heading */
  }
  
  .content-d-h p {
    font-size: 1.2em;
    margin: 10px 0;
    color: #000000; /* Dark grey color for paragraph */
  }



.two-column-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .content-about,
  .content-job-opportunities,
  .content-d {
    flex: 1;
    margin-right: 10px;
    padding: 20px;
  }
  
  .content-d {
    margin-right: 0; /* Remove margin from the last column */
  }
  
  .idf-img {
    width: 100%; /* Adjust image width */
    height: auto; /* Maintain aspect ratio */
    max-height: 400px; /* Limit max height */
    object-fit: cover; /* Cover the container */
    border-radius: 10px;
    padding: 0;
  }



.copy-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.copy-success {
  margin-left: 10px;
  color: green;
  font-weight: bold;
}
