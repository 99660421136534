.popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #4CAF50;
    color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 1000; /* Ensure popup is above other elements */
  }
  
  .popup.show {
    opacity: 1;
  }